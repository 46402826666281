import { BrowserOptions } from '@sentry/browser';

export interface AppConfig {
  auth: {
    service: string;
    frontend: string;
    autoRefreshSession: boolean;
    autoSyncSession: boolean;
    debug: boolean;
  };
  baseUrl: string;
  deletionServiceBaseUrl: string;
  chargebeeBaseUrl: string;
  environment: 'development' | string;
  // graphqlEndpoint: string
  reactEnvironment: 'development' | 'production' | string;
  // appstoreGraphqlEndpoint: string;
  release: string;
  systemGraphqlEndpoint: string;
  systemTokenExchangeEndpoint: string;
  sentry?: BrowserOptions;
  studio: {
    baseUrl: string;
  };
  signageImport: {
    allowFinalize: boolean;
    allowForceDelete: boolean;
    allowManual: boolean;
    enable: boolean;
  };
  // authSignageAudience: string
  version: string;
}

const release = `${REACT_APP_SENTRY_RELEASE}` || 'development';
const environment = `${REACT_APP_SENTRY_ENV}` || 'development';

export const appConfig: Readonly<AppConfig> = Object.freeze({
  // graphqlEndpoint: `${REACT_APP_GRAPHQL_ENDPOINT}`,
  // authSignageAudience: REACT_APP_SIGNAGE_AUDIENCE,
  auth: {
    service: `${REACT_APP_AUTH_SERVICE_BASE_URL}`,
    frontend: `${REACT_APP_AUTH_APP_BASE_URL}`,
    autoRefreshSession: `${REACT_APP_AUTH_REFRESH_SESSION}` === 'true',
    autoSyncSession: `${REACT_APP_AUTH_SYNC_SESSION}` === 'true',
    debug: `${REACT_APP_AUTH_DEBUG}` === 'true',
  },
  baseUrl: `${REACT_APP_BASE_URL}`,
  deletionServiceBaseUrl:`${REACT_APP_DELETE_SERVCE_URL}`,
  chargebeeBaseUrl:`${REACT_APP_CHARGEBEE_URL}`,
  environment,
  release,
  reactEnvironment: `${REACT_APP_ENV}` || 'development',
  // appstoreGraphqlEndpoint: `${REACT_APP_APPSTORE_GRAPHQL_ENDPOINT}`,
  sentry: REACT_APP_SENTRY_DSN
    ? {
      dsn: REACT_APP_SENTRY_DSN,
      environment,
      release,
    }
    : undefined,
  studio: {
    baseUrl: REACT_APP_STUDIO_BASE_URL,
  },
  systemGraphqlEndpoint: `${REACT_APP_MANAGEMENT_GRAPHQL_ENDPOINT}`,
  systemTokenExchangeEndpoint: `${(REACT_APP_MANAGEMENT_GRAPHQL_ENDPOINT || '')
    .replace(/\/graphql$/, '/auth')}`,
  signageImport: {
    allowFinalize: (`${REACT_APP_SIGNAGE_IMPORT_ALLOW_FINALIZE}` === 'true'),
    allowForceDelete: (`${REACT_APP_SIGNAGE_IMPORT_ALLOW_DELETE}` === 'true'),
    allowManual: (`${REACT_APP_SIGNAGE_IMPORT_ALLOW_MANUAL}` === 'true'),
    enable: (`${REACT_APP_SIGNAGE_IMPORT_ENABLE}` === 'true')
  },
  version: REACT_APP_SENTRY_RELEASE || 'development',
});
